<template>
  <div class="el-block--app-full">

    <div class="absolute top-0 z-30 w-full shadow-lg">
      <site-navigation />
    </div>




    <div
            v-if="!hasResults && !fetching"
            class="el-block--cytoscape relative particles-bg flex flex-col items-center justify-center">

      <div class="bg-gray-900 bg-opacity-75 w-full h-full absolute"></div>

      <div class="w-full z-30 p-4 text-center text-white max-w-4xl">
        <h1 class="text-5xl leading-tight flex w-full flex-col">

          <span class="text-6xl font-bold">Spark an idea with trending <span class="text-spark-blue">keywords</span></span>

        </h1>

        <p class="mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id arcu sem. Nullam bibendum placerat velit non convallis. Proin lectus odio, iaculis vitae augue a, vestibulum aliquet eros.</p>

      </div>


    </div>


    <div
            v-if="!hasResults && fetching"
            class="el-block--cytoscape relative particles-bg flex flex-col items-center justify-center">


        <div class="spinner z-30 my-6">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      <p class="text-lg text-white my-3 z-30">Please wait, we are fetching your results...</p>

      <div class="bg-gray-900 bg-opacity-75 w-full h-full absolute"></div>

    </div>


    <div
            v-if="hasResults"
            class="el-block--cytoscape relative">
      <cytoscape ref="cy" class="el-block--cytoscape bg-gray-900"
                 v-show="view === 'relationship'"
                 :config="config"
                 :preConfig="preConfig"
                 :afterCreated="afterCreated"
      >
      </cytoscape>

      <div class="el-block--cytoscape el-block--table pt-6 pb-12 overflow-hidden overflow-y-scroll" v-show="view === 'keywords'">
        <el-table
                :data="response.mapped ? response.mapped : []"
                style="width: 100%">

          <el-table-column
                  prop="keyword"
                  label="Keyword">
            <template slot-scope="scope">
              {{ scope.row.query }}
            </template>
          </el-table-column>
          <el-table-column
                  prop="index"
                  width="100"
                  label="Position">
            <template slot-scope="scope">
              {{ scope.row.index }}
            </template>
          </el-table-column>
          <el-table-column
                  prop="pare"
                  label="Parent Keyword">
            <template slot-scope="scope">
              {{ scope.row.parent_query }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="absolute bottom-0 w-full left-0 z-30 text-center mb-4">
        <el-radio-group v-model="view" size="small">
          <el-radio-button label="relationship">Relationship</el-radio-button>
          <el-radio-button label="keywords">Keywords</el-radio-button>
        </el-radio-group>
      </div>

      <div class="absolute top-0 right-0 z-30 m-6">
        <el-dropdown  @command="handleCommand">
          <el-button size="small" type="primary">
            Download <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exportChartAsSVG">Export Chart as SVG</el-dropdown-item>
            <el-dropdown-item command="downloadRelationships">Download Relationships</el-dropdown-item>
            <el-dropdown-item command="downloadKeywords">Download Keywords</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

    </div>


    <div class="absolute h-24 w-full bottom-0 bg-gray-200 shadow shadow-outline">
      <search-component
              v-model="opts.q"
              :hl="opts.hl"
              :gl="opts.gl"
              :fetching="fetching"
              @search="search"
      />
    </div>


  </div>
</template>

<script>

  //import cola from 'cytoscape-cola';
  import dagre from 'cytoscape-dagre';


  import Google from  "./../Modules/Google";
  const google = new Google();

    /* */

  import SiteNavigation from "../components/SiteNavigation";

  import { ExportToCsv } from 'export-to-csv';
  import SearchComponent from "../components/SearchComponent";


  export default {
    name: 'App',
    components: {SearchComponent, SiteNavigation},
    data() {
      return {
        view: 'relationship',
        fetching: false,
        response: {},
        opts: {
          q: '',
          hl: 'gb',
          gl: 'en'
        }
      }
    },
    mounted(){
      console.log('jo')
      if(this.$route && this.$route.query && this.$route.query.q && this.$route.query.hl && this.$route.query.gl && this.$route.query.recaptcha){
        console.log('Hello')
        this.opts = this.$route.query;
        this.search({
          q: this.$route.query.q,
          hl: this.$route.query.hl,
          gl: this.$route.query.gl,
          recaptcha: this.$route.query.recaptcha
        })
      }
    },
    methods: {
      handleCommand(command){
        if(command === 'downloadRelationships'){
          this.downloadRelationships();
        }
        if(command === 'downloadKeywords'){
          this.downloadKeywords();
        }

      },
      downloadKeywords(){

        let data = (this.response.terms ? this.response.terms : []).map((item)=>{
          return {
            keyword: item,
          }
        });

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Ayima Spark Keywords ' + this.term,
          useTextFile: false,
          useBom: true,
          //useKeysAsHeaders: true,
          headers: ['Keyword']
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

      },
      downloadRelationships(){

        let data = (this.response.mapped ? this.response.mapped : []).map((item)=>{
          return {
            query: item.query ? item.query : null,
            index: item.index ? item.index : null,
            parent_query: item.parent_query ? item.parent_query : null,
          }
        });

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Ayima Spark Relationships ' + this.term,
          useTextFile: false,
          useBom: true,
          //useKeysAsHeaders: true,
          headers: ['Keyword', 'Position', 'Parent Keyword']
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

      },

      search(opts){

        console.log(opts);

        let vm = this;
        vm.response = {};
        vm.fetching = true;

        google.search(opts.q.toString().trim(), opts.gl, opts.hl, opts.recaptcha).then((done)=>{
          vm.fetching = false;

          this.response = done;
        });



      },

      preConfig(cytoscape) {
        // cytoscape.use(cola);

        /*
        6Lcr2GIaAAAAADkUMY9a7-VRGa3j3Ebh8W2VU06t

         */
        cytoscape.use(dagre);
      },

      afterCreated(cy) {
        // cy: this is the cytoscape instance
        let edges = this.response.mapped.map((item) => {
          return {
            group: 'edges',
            data: {
              source: item.parent_id,
              target: item.id
            }
          }
        });

        cy.add(this.elements);

        for(let i = 0; i < edges.length; i++){
          try {
            cy.add(edges[i]);
          }catch (e) {
            console.log('E', e)
          }
        }

        cy.layout({
          name: 'dagre',
          rankDir: 'LR',
          rankSep: 350,
          edgeSep: 50,
          nodeSep: 10,
          fit: true,
        }).run();

        console.log('Reloaded')

      }
    },
    computed: {
      hasResults(){
        return this.response && this.response.mapped && this.response.mapped.length > 0
      },
      config() {
        return {
          autounselectify: true,
          boxSelectionEnabled: false,
          style: [
            {
              "selector": "edge[arrow]",
              "style": {
                "target-arrow-shape": "data(arrow)"
              }
            },
            {
              selector: "node",
              style: {
                "label": "data(label)",
                "background-color": "#15659f",
                "text-wrap": "wrap",
                "text-max-width": 80,
                "color": "#fff",
                "text-valign": "center",
                "text-halign": "center",
                "font-size": "10px"
              }
            },
            {
              selector: "edge",
              style: {
                "line-color": "#353535",
                'width': 2,
                'target-arrow-color': '#353535',
                'target-arrow-shape': 'triangle'
              }
            }
          ]
        }
      },
      elements(){
        return this.response.mapped.map((item) => {
          return {
            group: 'nodes',
            data: {
              id: item.id,
              label: item.query
            }
          }
        });
      }
    }
  }
</script>

<style>
  .grecaptcha-badge {
    display: none !important;
  }
</style>
