import axios from 'axios';

export default class Google  {


    search(term, hl, gl, recaptcha){

        return axios.get('https://spark.ayima.com/api/v1/spark', {
            params: {
                hl: hl,
                q: term,
                gl: gl,
                recaptcha: recaptcha
            }
        }).then((response)=>{
            return response.data.data;
        }).catch(()=>{
            return {};
        })
    }

}